<template>
  <div class="login">
    <div class="login_content">
      <div class="login_content_img">
        <div style="display: flex; align-items: center;">
          <img class="img1" src="../../assets/img/loginLogo1.png" alt="">
          <div class="lines"></div>
          <img class="img2" src="../../assets/img/loginLogo3.png" alt="">
        </div>

        <div class="back" @click="$router.replace({ path: '/home' })">返回</div>
      </div>

      <div class="login_form">
        <img class="img3" src="../../assets/img/loginBg.png" alt="">

        <div class="login_form_input">
          <div>
            <div class="text-center" style="margin-bottom: 18px;">欢迎回来</div>

            <div class="form_item" v-if="!newUser || !sendCode">
              <div class="label">手机号码</div>
              <div class="form_item_input">
                <i class="iconfont icon-huafei phone_icon"></i>
                <el-input ref="phoneInp" v-model="loginForm.loginname" maxlength="11" @input="onPhoneInput()" placeholder="请输入您的11位手机号码"></el-input>
                <div class="verification_code" v-if="loginType == 1 && !sendCode" @click="getPhoneCode(loginType)">
                  发送验证码
                </div>
                <div class="verification_code" v-if="loginType == 1 && sendCode">
                  {{ remainingSeconds }}s重发
                </div>
              </div>
            </div>

            <div class="form_item" v-if="newUser && sendCode">
              <div class="label">
                <span>请输入验证码</span>
                <span class="gray">
                  <span v-if="remainingSeconds">{{ remainingSeconds }}s</span>
                  <span @click.stop="resend">重发</span>
                </span>
              </div>
              <div class="label">
                <span>已发送验证码至 {{ loginForm.loginname }}</span>
                <span class="gray" @click="changePhone">换号码</span>
              </div>
            </div>

            <div class="form_item" style="margin-top: 14px;" v-if="loginType == 0">
              <div class="label">
                <span>登录密码</span>
                <!-- <span class="retrieve_password">找回密码？</span> -->
              </div>
              <div class="form_item_input">
                <i class="iconfont icon-mima phone_icon"></i>
                <el-input v-model="loginForm.password" type="password" @keyup.native.enter="$globalFun.throttle(login, 500, true)" placeholder="请输入您的登录密码"></el-input>
              </div>
            </div>

            <div class="form_item" style="margin-top: 14px;" v-if="loginType == 1">
              <div class="form_item_code_input">
                <el-input v-model="code1" ref="code1" maxlength="1" @input="oninput('code2', code1)" class="code_item"></el-input>
                <el-input v-model="code2" ref="code2" maxlength="1" @keyup.native.delete="del('code1')" @input="oninput('code3', code2)" class="code_item"></el-input>
                <el-input v-model="code3" ref="code3" maxlength="1" @keyup.native.delete="del('code2')" @input="oninput('code4', code3)" class="code_item"></el-input>
                <el-input v-model="code4" ref="code4" maxlength="1" @keyup.native.delete="del('code3')" class="code_item"></el-input>
              </div>
              <div class="code_wrong">{{ codeFlag?'验证码有误，请重新输入':'' }}</div>
            </div>

            <div class="login_btn" :class="loginType == 1?'login_btn_margin0':''" @click="login" v-if="!newUser || sendCode">
              登录
            </div>

            <div class="login_btn" :class="loginType == 1?'login_btn_margin0':''" @click="getPhoneCode(loginType)" v-else>
              发送验证码
            </div>

            <div class="change_model" v-if="newUser"></div>

            <div class="change_model" @click="$globalFun.throttle(changeType, 500, true)" v-else>
              {{ loginType == 0?'手机验证登录':'账号密码登录' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <AllFooter ref="AllFooter"></AllFooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginType: 0,
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      sendCode: false,
      sendCodeTimer: null,
      remainingSeconds: 0,
      codeFlag: false,
      newUser: false,

      loginForm: {
        loginname: "",
        password: "",
      },

      loginnameRules: /^1[3|4|5|6|7|8|9]\d{9}$/,
      // passwordRules: '/^[\w]{6,12}$/',

      rules: {
        loginname: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "密码长度在6到12个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {
    
  },

  mounted() {
    document.documentElement.scrollTop = 0
    this.$nextTick(() => {
      this.$refs.phoneInp.focus()
    })
  },

  methods: {
    // 切换登录方式
    changeType() {
      this.loginType = this.loginType ? 0 : 1;

      if (this.loginType == 0) {
        this.sendCode = false
      } else {
        this.$nextTick(() => {
          this.$refs['phoneInp'].focus()
        })
      }
    },

    // 发送验证码
   async getPhoneCode(loginType) {
     const loginnameRules = /^1[3|4|5|6|7|8|9]\d{9}$/

      if (!this.loginForm.loginname) {
        return this.$message('请输入手机号码');
      } else if (this.remainingSeconds > 0) {
        return this.$message('请' + this.remainingSeconds + '秒后重试');
      } else if (!loginnameRules.test(this.loginForm.loginname)) {
        return this.$message('请输入正确的号码格式');
      }

      const { data } = await this.$api.user.getCode({
        loginname: this.loginForm.loginname
      })
      console.log(data)

      this.sendCode = true

      if (loginType == 0 && this.newUser) {
        this.loginType = 1

        this.$nextTick(() => {
          this.$refs['code1'].focus()
        })
      }

      this.remainingSeconds = 60
      clearInterval(this.sendCodeTimer)
      this.sendCodeTimer = setInterval(() => {
        this.remainingSeconds--

        if (this.remainingSeconds == 0) clearInterval(this.sendCodeTimer)
      }, 1000)
    },

    // 重发验证码
    resend() {
      if (this.remainingSeconds > 0) return
      this.getPhoneCode(this.loginType)
    },

    // 修改号码
    changePhone() {
      this.sendCode = false
      this.loginType = 0
    },

    onPhoneInput() {
      if (this.loginForm.loginname.length < 11) {
        this.newUser = false
        return
      }
      if (this.loginnameRules.test(this.loginForm.loginname)) {
        this.checkPhone(this.loginForm.loginname)
      }
    },

    oninput(e, data) {
      console.log('1', data)
      if (this.$refs[e] && data) {
        this.$refs[e].focus()
      }
    },

    del(e) {
      console.log('2')
      this.$refs[e].focus()
    },

    onfocus() {
      // console.log('2', e)
    },

    // 判断手机号是否已注册
    async checkPhone(phone) {
      const { data } = await this.$api.user.checkPhone({ phone })
      console.log('判断手机号是否已注册', data)
      this.newUser = data

      if (this.newUser) {
        this.sendCode = false
        this.loginType = 0
      }
    },

    // 登录
    login() {
      const loginnameRules = /^1[3|4|5|6|7|8|9]\d{9}$/
      const passwordRules = /^[\w]{6,60}$/

      if (!loginnameRules.test(this.loginForm.loginname)) {
        return this.$message('请输入正确的号码格式');
      } else if (!passwordRules.test(this.loginForm.password) && this.loginType == 0) {
        return this.$message('密码至少为6位');
      }

      if (this.loginType == 1) {
        this.loginForm.type = 1
        this.loginForm.code = this.code1 + this.code2 + this.code3 + this.code4

        if (!this.newUser) {
          delete this.loginForm.password
        }
      }

      this.$store
          .dispatch("login", this.loginForm)
          .then((res) => {
            console.log("用户信息", res);
            if (res === 'error') {
              const err = this.newUser?'账号不存在':'密码错误'
              this.$message.error(err)
            } else {
              this.$router.go(-1);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;

  .login_content {
    width: 1044px;
    height: 480px;
    margin: 40px auto 0;
    border-bottom: 1px solid #eeeeee;

    .login_content_img {
      width: 960px;
      height: 90px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img1 {
        width: 197px;
        height: 58px;
        display: block;
      }

      .lines {
        width: 0px;
        height: 40px;
        margin: 0 10px 0 26px;
        border: 1px solid #e6e6e6;
      }

      .img2 {
        width: 152px;
        height: 90px;
        display: block;
      }

      .back {
        width: 100px;
        height: 30px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid #d9d9d9;
        border-radius: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: #5d5d5d;
        cursor: pointer;
      }
    }

    .login_form {
      width: 960px;
      height: 287px;
      margin: 18px auto 0;
      background: rgba(249, 249, 249, 1);
      border-radius: 10px;
      padding: 15px 13px 18px;
      display: flex;
      align-items: center;

      .img3 {
        width: 598px;
        height: 286px;
        display: block;
      }

      .login_form_input {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #989898;
        text-align: left;

        .form_item {
          
          .label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            color: #000000;
            display: flex;
            justify-content: space-between;

            .retrieve_password {
              color: #5A5A5A;
              cursor: pointer;
            }

            .gray {
              color: #898989;
              cursor: pointer;
            }
          }

          .form_item_input {
            position: relative;

            .phone_icon {
              color: #969696;
              font-size: 14px;
              position: absolute;
              top: 13px;
              left: 0;
              z-index: 9;
            }

            .verification_code {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              color: #6CC966;
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
            }

            /deep/ .el-input__inner {
              background-color: #f9f9f9;
              border-radius: 0;
              border: none;
              border-bottom: 1px solid #d0d0d0;
              padding: 0 20px !important;
            }
          }
        
          .form_item_code_input {
            display: flex;
            justify-content: center;
            align-items: center;

            .code_item {
              width: 48px;
              height: 48px;
              background: rgba(255, 255, 255, 1);
              border: 1px solid #efefef;
              opacity: 1;
              border-radius: 5px;
              margin-left: 18px;
              display: flex;
              align-items: center;
              justify-content: center;

              /deep/ .el-input__inner {
                width: 48px !important;
                height: 24px !important;
                border: none !important;
                padding: 0 !important;
                font-size: 28px !important;
                font-family: Microsoft YaHei !important;
                font-weight: bold !important;
                color: #898989 !important;
                line-height: 48px !important;
                text-align: center !important;
              }
            }

            .code_item:first-child {
              margin-left: 0;
            }
          }

          .code_wrong {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8B8B8B;
          }
        }

        .login_btn {
          width: 264px;
          height: 35px;
          margin: 20px auto 0;
          background: linear-gradient(180deg, #ff4536 0%, #dd3b2e 100%);
          box-shadow: 0px 5px 10px rgba(255, 118, 118, 0.35);
          border-radius: 5px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .login_btn_margin0 {
          margin-top: 0 !important;
        }

        .change_model {
          height: 20px;
          line-height: 20px;
          text-align: center;
          margin-top: 14px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #484848;
          cursor: pointer;
        }
      }
    }
  }
}
</style>